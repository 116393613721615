<template>
	<div class="dealer-login">
		<form @submit.prevent="login" class="login-form">
			<div class="form-control">
				<i class="el-icon-mobile-phone"></i>
				<input type="text" v-model="ruleForm.phone" placeholder="请输入手机号" />
			</div>
			<div class="form-control">
				<i class="el-icon-lock"></i>
				<input type="password" v-model="ruleForm.password" placeholder="请输入密码" />
			</div>
			<div class="link">
				<div @click="registerClick"><span>立即注册</span></div>
				<div @click="forgotPassword"><span>忘记密码</span></div>
			</div>
			<button class="submit-btn" type="submit">登录</button>
		</form>
		<div class="qrcode">
			<el-tooltip placement="bottom" effect="light" class="qrcode-item">
				<div class="text" @mouseenter="wxLogin" @mouseleave="dialogVisible = false">
					<el-image class="img" :src="require('@/assets/img/login/login-icon01.png')" fit="contain" />
					<span>微信扫码登录</span>
				</div>
				<div slot="content">
					<el-image class="code-img" :src="qrcode" fit="contain" style="width: 100px;">
						<div slot="error" class="image-slot">
							<i class="el-icon-loading"></i> 正在加载...
						</div>
					</el-image>
				</div>
			</el-tooltip>
			<el-tooltip placement="bottom" effect="light" class="qrcode-item">
				<div class="text">
					<el-image class="img" :src="require('@/assets/img/login/login-icon02.png')" fit="contain" />
					<span>快智配客服群</span>
				</div>
				<div slot="content">
					<el-image class="code-img" :src="require('@/assets/img/login/service-code.jpg')" fit="contain"
						style="width: 100px;" />
				</div>
			</el-tooltip>
		</div>
	</div>

</template>
<script>
	import axios from 'axios';
	import {
		getMpPathQrcode,
		loginMpPathQrcode
	} from "@/api/user";
	export default {
		data() {
			return {
				ruleForm: {
					phone: "",
					password: "",
					type: 2,
				},
				dialogVisible: false,
				wxLoginCode: '',
				qrcode: ''
			}
		},
		methods: {
			forgotPassword() {
				const url = 'http://search.kuaizhipei.com/forgotPassword';
				window.open(url, '_blank');
			},
			registerClick() {
				const url = 'http://search.kuaizhipei.com/register';
				window.open(url, '_blank');
			},
			login() {
				let Phone_reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				if (!Phone_reg.test(this.ruleForm.phone)) {
					this.$message.error('请输入手机号')
				} else if (!this.ruleForm.password) {
					this.$message.error('请输入密码')
				} else {
					axios.post('/api/user/login', this.ruleForm)
						.then(res => {
							if (res.data.code == 0) {
                this.loginSearch(res.data.data.token)
							} else {
								this.$message.error(res.data.desc);
							}
						})
						.catch(error => {
							// 处理错误
							console.error(error);
						});
				}

			},
      loginSearch(token) {
        const searchUrl = process.env.VUE_APP_SEARCH_URL;
        window.location.href = searchUrl + '?from=sso&token=' + token;
      },
			wxLogin() {
        this.dialogVisible = true
				this.wxLoginCode = this.randomString(16)
				this.createQRCode()
				this.checkIsLogged()
			},
			createQRCode() {
				getMpPathQrcode({
					code: this.wxLoginCode
				}).then(res => {
					this.qrcode = 'data:image/jpg;base64,' + res.data
				})
			},
			checkIsLogged() {
				loginMpPathQrcode({
					code: this.wxLoginCode
				}).then(res => {
					if (res.data) {
            this.loginSearch(res.data.token)
					} else {
						if (this.dialogVisible) {
							setTimeout(() => {
								this.checkIsLogged()
							}, 1000)
						}
					}
				})
			},

			randomString(e) {
				e = e || 32;
				var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
					a = t.length,
					n = "";
				for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
				return n
			}

		}
	}
</script>
<style lang="scss" scoped>
	@import '~@/assets/css/login.scss';
	.qrcode{
		display: flex;
		justify-content: flex-end;
		margin: 0.2rem 0;
		.qrcode-item{
			cursor: pointer;
			margin: 0 0 0 0.2rem;
			display: flex;
			align-items: center;
			color: #303030;
			.img{
				margin: 0 0.05rem 0 0;
			}
		}
	}
	::v-deep .image-slot{
		display: flex;
		justify-content: center;
		align-items: center;
		color: #999;
		i{
			font-size: 20px;
			margin: 0 10px 0 0;
		}
	}
</style>
